.masthead {
  position: relative;
  background-color: #343a40;
  /*
  background: url("bg-masthead.jpg") no-repeat center center;
	*/
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.masthead:before {
  content: "";
  position: absolute;
  background-color: #01454b;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}

.masthead {
  font-size: 2rem;
}
.masthead h1 {
  font-weight: 800;
  font-size: 3rem;
}
.masthead .btn-white:not(:hover) {
  background-color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
  .masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .masthead h1 {
    font-size: 4rem;
  }
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}
@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.border-black {
  border: 1px solid #000000;
}

.dot {
  animation: wave 1.5s infinite ease-in-out;
  display: inline-block;
  margin-left: 2px;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes wave {
  0%,
  66.666% {
    transform: translateY(0);
  }
  33.333% {
    transform: translateY(-5px);
  }
}
